<template>
	<div>
		<h1 class="primary--text mt-2">
			<v-icon large color="orange darken-2"> mdi-package-variant </v-icon>
			CRServices
		</h1>
	</div>
</template>

<script>
export default {
	name: "AppLogo"
};
</script>
<style scoped>
.mdi-package-variant {
	font-size: 90px !important;
}
</style>
