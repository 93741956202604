<template>
	<v-row>
		<v-col cols="12">
			<h3 class="font-weight-medium pb-1 text-uppercase">
				<span class="font-weight-bold"> Services </span>
				/ Bill of Lading
			</h3>
			<v-divider></v-divider>
		</v-col>

		<v-row>
			<v-col cols="12" class="text-uppercase grey--text pt-8">
				<v-card class="mx-auto grey lighten-5 mb-4 pa-4 mb-5" id="bill-of-lading" max-width="1150" elevation="1">
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="8">
									<h2 class="mb-4">Bill of Lading</h2>
									<h4>Date: {{ getDateOnly(getLead.customerAction.confirmedDateOfEstimates) }}</h4>
									<h4>Time: {{ getFullTime(getLead.customerAction.confirmedDateOfEstimates) }}</h4>
									<h4>Reference No: #{{ getLead.lead_id.toString().padStart(4, 0) }} | {{ getLead.leadType }}</h4>
								</v-col>
								<v-col cols="4" class="text-right">
									<div class="text-center pb-4">
										<appLogo></appLogo>
									</div>
								</v-col>
							</v-row>
							<v-row class="my-1">
								<v-col cols="3">
									<span class="text-uppercase"> usdot #1877921 </span>
								</v-col>
								<v-col cols="2">
									<span class="text-uppercase"> Mc #677599 </span>
								</v-col>
								<v-col cols="4">
									<span class="text-uppercase"> Address 519 Cascade ct Sewell NJ 08080 </span>
								</v-col>
								<v-col cols="3">
									<span class="text-uppercase"> Phone #877-385-2919 </span>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row class="grey lighten-2 mt-6 mx-0 mb-4">
								<v-col cols="6">
									<h3 class="text-uppercase pl-6">Move From</h3>
								</v-col>
								<v-col cols="6">
									<h3 class="text-uppercase pl-6">Move To</h3>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="6">
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Customer Name:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 text-capitalize pl-2 font-weight-medium text-right">
											{{ getCustomerData.firstName }} {{ getCustomerData.lastName }}
										</v-col>
									</v-row>

									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Phone:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getCustomerData.phone.home }}
										</v-col>
									</v-row>

									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Mobile Phone:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getCustomerData.phone.hasOwnProperty("mobile") ? getCustomerData.phone.mobile : "" }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Work Phone:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getCustomerData.phone.work }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Email:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 text-lowercase font-weight-medium text-right">
											{{ getCustomerData.email }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="11">
											<v-divider></v-divider>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Pick-up Address:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right text-capitalize">
											{{ getLead.originInfo.pickupAddress }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Floor:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.originInfo.floor }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">City:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right text-capitalize">
											{{ getCity(getLead.originInfo.fromCity) }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Zipcode:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.originInfo.fromZipcode }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">State:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getState(getLead.originInfo.fromState) }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="11">
											<v-divider></v-divider>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Parking:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.contactInfo.parking }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Additional Stop:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.contactInfo.additionalStop }}
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="6">
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Customer Name:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right text-capitalize">
											{{ getCustomerData.firstName }} {{ getCustomerData.lastName }}
										</v-col>
									</v-row>

									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Phone:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getCustomerData.phone.home }}
										</v-col>
									</v-row>

									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Mobile Phone:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getCustomerData.phone.mobile }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Work Phone:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getCustomerData.phone.work }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Email:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-capitalize text-right">
											{{ getCustomerData.email }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12">
											<v-divider></v-divider>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Pick-up Address:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right text-capitalize">
											{{ getLead.destinationInfo.destinationAddress }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Floor:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.destinationInfo.destinationFloor }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">City:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right text-capitalize">
											{{ getCity(getLead.destinationInfo.destinationCity) }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Zipcode:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.destinationInfo.destinationZipcode }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">State:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getState(getLead.destinationInfo.destinationState) }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12">
											<v-divider></v-divider>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Parking:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.contactInfo.parking }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 grey--text text--lighten-1 font-weight-medium">Additional Stop:</v-col>
										<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.contactInfo.additionalStop }}
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row class="mt-8">
								<v-col cols="12" class="pb-1">
									<h3 class="text-uppercase grey lighten-2 py-4 pl-4">Rates</h3>
								</v-col>

								<!-- Estimates Table -->
								<estimates-table @initialPayment="updatePartialDeposit">
									<template #initialDepositNote> Notes: Any additional time needed in 1/2 hour incerements </template>
								</estimates-table>

								<v-col cols="12" class="pt-1">
									<h4 class="font-weight-bold text-uppercase mb-2">Payment Terms: Initital Payment: {{ initialPayment | formatDollarNumber }}</h4>
									<h4 class="font-weight-bold text-uppercase">
										Remaining balances is due cash or online payment. Please have your payment ready when crew delivers.
									</h4>
								</v-col>
							</v-row>

							<v-row class="grey lighten-2 mx-0 mt-6">
								<v-col cols="6">
									<h3 class="text-uppercase py-0 pl-4">Trip Details</h3>
								</v-col>
								<v-col cols="6">
									<h3 class="text-uppercase py-0 pl-4">Total Hours</h3>
								</v-col>
							</v-row>
							<v-row class="mt-6">
								<v-col cols="6">
									<v-row>
										<v-col cols="6" class="py-1 font-weight-medium">Mileage Between:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
											{{ getLead.contactInfo.mileageBetweenLocation }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 font-weight-medium">Estimated Weight:</v-col>
										<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right text-lowercase">
											{{
												getLead.contactInfo.hasOwnProperty("approxWeight") && getLead.contactInfo.approxWeight.length > 0
													? `${getLead.contactInfo.approxWeight}lbs`
													: ""
											}}
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="6">
									<v-row>
										<v-col cols="6" class="py-1 font-weight-medium">Start Time:</v-col>
										<v-col cols="6" class="py-1 blue-grey--text text--darken-2 pl-2 font-weight-medium text-right text-capitalize">
											{{ getStartTime.length > 0 ? getTimeFirstWithDate(getStartTime) : "Not set yet" }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 font-weight-medium">Finish Time:</v-col>
										<v-col cols="6" class="py-1 blue-grey--text pl-2 font-weight-medium text-right text-capitalize">
											{{ getFinishTime.length > 0 ? getTimeFirstWithDate(getFinishTime) : "Not set yet" }}
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="py-1 font-weight-medium">Total Hours:</v-col>
										<v-col cols="6" class="py-1 blue--text text--darken-2 pl-2 font-weight-bold text-right text-lowercase">
											{{ getTimeDifference }}
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row class="mt-6">
								<v-col cols="12">
									<h3 class="text-uppercase">Valuation Coverage:</h3>
								</v-col>
								<v-col cols="12" class="py-1">
									<h4 class="text-uppercase">Customers declared value and limit of companys liability</h4>
								</v-col>
								<v-col cols="12" class="pt-1 body-2">
									Since rates are based on declared value of the property, and the customer (shipper) is required to declare in writing the released value ot the
									property, the agreed or declared value of the property is hereby specifically stated to be not exceeding $0.60 cents per pound article with a
									limit of $2,000.00 while being handled by the carrier.
								</v-col>
								<v-col cols="6">
									<v-container class="fill-height">
										<v-row class="align-end fill-height">
											<template
												v-if="
													getLead.hasOwnProperty('customerAction') &&
													getLead.customerAction.hasOwnProperty('valuationCoverageCustomerrInititalSignature') &&
													getLead.customerAction.valuationCoverageCustomerrInititalSignature.length > 0
												"
											>
												<v-col cols="12" class="text-center mx-4">
													<v-img :src="getLead.customerAction.valuationCoverageCustomerrInititalSignature" max-height="125" contain class=""></v-img>
													<v-divider class="mb-1 mt-2"></v-divider>
													<label for="customer-initital-signature" class="font-weight-bold">Customer Signature</label>
												</v-col>
											</template>
											<template v-else>
												<v-col cols="5" class="text-right">
													<span class="font-weight-bold">Customer Signature:</span>
												</v-col>
												<v-col cols="7">
													<v-text-field hide-details disabled></v-text-field>
												</v-col>
											</template>
										</v-row>
									</v-container>
								</v-col>
								<v-col cols="6">
									<v-container class="fill-height">
										<v-row class="align-end fill-height">
											<template
												v-if="
													getLead.hasOwnProperty('customerAction') &&
													getLead.customerAction.hasOwnProperty('valuationCoverageDriverInititalSignature') &&
													getLead.customerAction.valuationCoverageDriverInititalSignature.length > 0
												"
											>
												<v-col cols="12" class="text-center mx-4">
													<v-img :src="getLead.customerAction.valuationCoverageDriverInititalSignature" max-height="125" contain class=""></v-img>
													<v-divider class="mb-1 mt-2"></v-divider>
													<label for="driver-initital-signature" class="font-weight-bold">Driver Signature</label>
												</v-col>
											</template>
											<template v-else>
												<v-col cols="5" class="text-right">
													<span class="font-weight-bold">Driver Name:</span>
												</v-col>
												<v-col cols="6">
													<v-text-field hide-details disabled></v-text-field>
												</v-col>
											</template>
										</v-row>
									</v-container>
								</v-col>
							</v-row>
							<!-- High value inventory table -->
							<!-- <v-row class="mt-8">
								<v-col cols="12">
									<v-simple-table fixed-header class="high-value-inventory">
										<template v-slot:default>
											<thead>
												<tr>
													<th colspan="4" class="text-center font-weight-bold light-blue lighten-5 grey--text text--darken-1 text-uppercase title">
														High Value Inventory
													</th>
												</tr>
												<tr>
													<th class="text-left body-2 font-weight-bold grey--text text--darken-1 bg-light-blue">Items</th>
													<th class="text-center body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Quantity</th>
													<th class="text-center body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Price</th>
													<th class="text-left body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Comments</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in getLead.inventory" :key="index">
													<td>
														<span class="font-weight-medium ml-6">{{ item.itemName }}</span>
													</td>
													<td class="font-weight-bold text-center">{{ item.qty }}</td>
													<td class="font-weight-bold text-center"></td>
													<td class="font-weight-bold text-center"></td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-col>
							</v-row> -->
							<v-row class="mt-8">
								<v-col cols="12">
									<h3 class="text-uppercase mb-2">Shipper Signature on pick up</h3>
									<div>
										This is to certify that the above named materials are properly classified, packaged, marked, and labeled, and are in proper condition for
										transportation according to the applicable regulations of the DOT. This contract is non negotiable and all monies are due COD are described
										in your contract.
									</div>
								</v-col>
							</v-row>
							<v-row class="mt-10">
								<v-col cols="12" class="mt-6">
									<label for=""> Additional Comments</label>
									<v-textarea outlined background-color="grey lighten-4" rows="3" class="mt-1"></v-textarea>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="mt-6">
									<v-row class="align-end">
										<v-col cols="4" class="text-center">
											<v-text-field
												id="customer-name"
												class="text-center-important"
												hide-details
												disabled
												:value="`${getCustomerData.firstName} ${getCustomerData.lastName}`"
											></v-text-field>
											<label for="customer-name" class="font-weight-bold">Customer Name</label>
										</v-col>
										<v-col cols="4" class="text-center">
											<!-- <v-text-field id="customer-signature" hide-details=""></v-text-field>
											<label for="customer-signature" class="font-weight-bold">Signature</label> -->
											<!-- <v-btn depressed color="primary"> Signature </v-btn> -->

											<template v-if="checkCompletedSign">
												<v-img :src="getLead.customerAction.completedDateSignature" max-height="125" contain class=""></v-img>
												<v-divider></v-divider>
											</template>
											<template v-else>
												<v-text-field id="customer-completed-signature" disabled hide-details=""></v-text-field>
											</template>
											<label for="customer-completed-signature" class="font-weight-bold">Customer Signature</label>
										</v-col>
										<v-col cols="4" class="text-center">
											<v-text-field class="text-center-important" disabled id="customer-date" hide-details :value="getDateOnly(Date.now())"></v-text-field>
											<label for="customer-date" class="font-weight-bold">Date</label>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="pt-0">
				<v-container class="pt-0">
					<v-row class="mb-10">
						<v-col cols="12" class="text-right py-0">
							<v-btn text class="font-weight-bold" @click="printDoc">
								<v-icon class="mr-2"> mdi-printer </v-icon>
								Print
							</v-btn>
						</v-col>
						<v-col cols="12" class="text-center pt-0">
							<v-btn
								@click="completeAndSign"
								:disabled="submitData"
								color="blue darken-1"
								height="45"
								:dark="!submitData"
								depressed
								class="font-weight-bold text-capitalize"
							>
								<template v-if="submitData">
									<v-progress-circular indeterminate size="22" class="mr-2" color="blue"></v-progress-circular>
									Please wait...
								</template>
								<template v-else> Sign and Complete Here! </template>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
		<customer-sign-and-confirmed ref="signAndConfirmed"> Add your signature here to complete your delivery transaction! </customer-sign-and-confirmed>
	</v-row>
</template>


<script>
import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
import { mapGetters, mapActions } from "vuex";

const options = {
	name: "_blank",
	specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
	styles: [
		"https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css",
		"https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons",
		"https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",

		"https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
		"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
		"http://localhost:8000/print.css"
	],
	windowTitle: window.document.title
};

Vue.use(VueHtmlToPaper, options);

import appLogo from "Components/app/appLogo";
import EstimatesTable from "Components/clientsBilling/commonOnTabs/estimatesTable";
import CustomerSignAndConfirmed from "Components/Dialogs/CustomerSignAndConfirmed";

export default {
	name: "BillingTabThird",
	components: {
		appLogo,
		EstimatesTable,
		CustomerSignAndConfirmed
	},
	data() {
		return {
			partialPayment: null,
			submitData: false
		};
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"]),
		initialPayment() {
			return this.partialPayment;
		},
		checkCompletedSign() {
			return (
				this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("completedDateSignature") &&
				this.getLead.customerAction.completedDateSignature.length > 0
			);
		},
		getStartTime() {
			// getTimeFirstWithDate(date){
			return this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursDriverConfirmedSignatureDate") &&
				this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate.length > 0
				? this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate
				: "";
		},
		getFinishTime() {
			// getTimeFirstWithDate(date){
			return this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursReceiverConfirmedSignatureDate") &&
				this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate.length > 0
				? this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate
				: "";
		},
		getTimeDifference() {
			let result = "";

			if (this.getStartTime.length > 0 && this.getFinishTime.length > 0) {
				let start = this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate;
				let finish = this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate;

				let diffInMilliSeconds = Math.abs(new Date(start) - new Date(finish));

				const hour = Math.floor(diffInMilliSeconds / 3600000);
				const min = Math.floor(diffInMilliSeconds / 1000 / 60);

				result = `${min} min.`;
				if (hour > 0) {
					let hrExt = hour > 1 ? "hrs." : "hr.";
					result = `${hour} ${hrExt}`;
				}
				// diffInMilliSeconds -= hours * 3600;
				console.log(result);
			}
			return result;
		}
	},
	methods: {
		...mapActions("CustomerUnique", ["updateLead"]),
		...mapActions("Notification", ["successNotice"]),
		updatePartialDeposit(val) {
			this.partialPayment = val;
		},
		async printDoc() {
			await this.$htmlToPaper("bill-of-lading");
			console.log("printing");
		},
		completeAndSign() {
			this.$refs.signAndConfirmed.open().then(async res => {
				console.log(res, "boom");

				if (!res.signed) {
					return;
				}

				// load btn loaders
				this.submitData = true;

				//do update the lead status to 3
				let authKey = this.$route.params.authKey;
				let crsKey = this.$route.params.crsKey;
				let id = this.$route.params.leadId;
				let customerId = this.$route.params.customerId;

				let customerAction = this.getLead.customerAction;

				customerAction["completedDateSign"] = Date.now();
				customerAction["completedDateSignature"] = res.svg;

				let params = {
					id,
					authKey,
					crsKey,
					leadId: id,
					customerId,
					status: 4,
					customerAction
				};

				let update = await this.updateLead(params);

				// display success notification
				if (update.status == 200) {
					await this.successNotice({
						title: "Transactions Complete!",
						description: "Cool! transactions completed."
					});
				}
				// remove btn loaders
				this.submitData = false;
			});
		}
	}
};
</script>

<style scoped>
.bg-light-blue {
	background: #e1f5fe !important;
}
>>> .v-data-table table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table thead tr th,
>>> .v-data-table table tbody tr td {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table thead tr th:last-child,
>>> .v-data-table table tbody tr td:last-child,
>>> .v-data-table:first-child table tbody tr:last-child td,
>>> .v-data-table:last-child table tbody tr:last-child td {
	border-right: unset;
}
>>> .v-data-table.high-value-inventory table tbody tr:last-child td {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .text-center-important .v-input__control .v-input__slot .v-text-field__slot input {
	text-align: center !important;
}

>>> .v-data-table table:first-child tbody tr td:first-child {
	border-right: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>