<template>
	<keep-alive>
		<component :is="servicesMenuSelected"></component>
	</keep-alive>
</template>

<script>
import BillOfLading from "Components/clientsBilling/billingTabs/tab3/MenuPages/BillOfLading";
import AddTotalHours from "Components/clientsBilling/billingTabs/tab3/MenuPages/AddTotalHours";
import ValuationCaverage from "Components/clientsBilling/billingTabs/tab3/MenuPages/ValuationCaverage";
import HighValueInventory from "Components/clientsBilling/billingTabs/tab3/MenuPages/HighValueInventory";
import Documents from "Components/clientsBilling/billingTabs/tab3/MenuPages/Documents";
import Claims from "Components/clientsBilling/billingTabs/tab3/MenuPages/Claims";

export default {
	name: "BillingTabThird",
	components: {
		BillOfLading,
		AddTotalHours,
		ValuationCaverage,
		HighValueInventory,
		Documents,
		Claims
	},
	props: {
		servicesMenuSelected: {
			type: String,
			default: "BillOfLading"
		}
	},
	data() {
		return {
			// menuSelected: "BillOfLading"
		};
	},
	methods: {}
};
</script>

<style scoped>
</style>