<template>
	<v-row class="my-6">
		<v-col cols="12">
			<h3 class="font-weight-medium pb-1 text-uppercase">
				<span class="font-weight-bold"> Services </span>
				/ High Value Items
			</h3>
			<v-divider></v-divider>
		</v-col>
		<v-col cols="12" class="mt-10">
			<v-simple-table fixed-header class="high-value-inventory">
				<template v-slot:default>
					<thead>
						<tr>
							<th colspan="4" class="text-center font-weight-bold light-blue lighten-5 grey--text text--darken-1 text-uppercase title">High Value Inventory</th>
						</tr>
						<tr>
							<th class="text-left body-2 font-weight-bold grey--text text--darken-1 bg-light-blue text-uppercase">Items</th>
							<th class="text-center body-2 font-weight-bold bg-light-blue grey--text text--darken-1 text-uppercase">Quantity</th>
							<th class="text-center body-2 font-weight-bold bg-light-blue grey--text text--darken-1 text-uppercase">Price</th>
							<th class="text-left body-2 font-weight-bold bg-light-blue grey--text text--darken-1 text-uppercase">Comments</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in getLead.inventory" :key="item.itemName">
							<td>
								<span class="font-weight-medium ml-6">{{ item.itemName }}</span>
							</td>
							<td class="font-weight-bold text-center">{{ item.qty }}</td>
							<td class="font-weight-bold text-center"></td>
							<td class="font-weight-bold text-center"></td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HighValueInventory",
	data() {
		return {};
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"])
	}
};
</script>

<style scoped>
.bg-light-blue {
	background: #e1f5fe !important;
}
>>> .v-data-table table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table thead tr th,
>>> .v-data-table table tbody tr td {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table thead tr th:last-child,
>>> .v-data-table table tbody tr td:last-child,
>>> .v-data-table:first-child table tbody tr:last-child td,
>>> .v-data-table:last-child table tbody tr:last-child td {
	border-right: unset;
}
>>> .v-data-table.high-value-inventory table tbody tr:last-child td {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>