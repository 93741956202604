var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-medium pb-1 text-uppercase"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Services ")]),_vm._v(" / Valuation Coverage ")]),_c('v-divider')],1),_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-uppercase mb-1"},[_vm._v("CUSTOMERS DECLARED VALUE AND LIMIT OF COMPANYS LIABILITY")]),_c('div',[_vm._v(" Since rates are based on the declared value of the property, and the customer (shipper) is required to declare in writing the released value of the property, the agreed or declared value of the property is hereby specifically stated to be not exceeding $0.60 cents per pound per article with a limit of $2,000.00 while being handled by the carrier. ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"align-end"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"4 "}},[(
						_vm.getLead.hasOwnProperty('customerAction') &&
						_vm.getLead.customerAction.hasOwnProperty('valuationCoverageCustomerrInititalSignature') &&
						_vm.getLead.customerAction.valuationCoverageCustomerrInititalSignature.length > 0
					)?[_c('v-img',{attrs:{"src":_vm.getLead.customerAction.valuationCoverageCustomerrInititalSignature,"max-height":"125","contain":""}}),_c('v-divider',{staticClass:"mt-2 mb-1"})]:[_c('v-text-field',{attrs:{"id":"customer-initital-signature","disabled":"","hide-details":""}})],_c('label',{staticClass:"font-weight-bold",attrs:{"for":"customer-initital-signature"}},[_vm._v("Customer Signature")]),_c('div',{staticClass:"mt-4"},[_c('v-btn',{staticClass:"text-capitalize font-weight-bold",attrs:{"dark":!_vm.customerSubmitData,"disabled":_vm.customerSubmitData,"color":"blue darken-2","height":"45","depressed":""},on:{"click":function($event){return _vm.signAndSignature('customer')}}},[(_vm.customerSubmitData)?[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","size":"22","color":"blue"}}),_vm._v(" Please wait... ")]:[_vm._v(" Customer Signature to Confirmed ")]],2)],1)],2),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4","offset":"1"}},[(
						_vm.getLead.hasOwnProperty('customerAction') &&
						_vm.getLead.customerAction.hasOwnProperty('valuationCoverageDriverInititalSignature') &&
						_vm.getLead.customerAction.valuationCoverageDriverInititalSignature.length > 0
					)?[_c('v-img',{attrs:{"src":_vm.getLead.customerAction.valuationCoverageDriverInititalSignature,"max-height":"125","contain":""}}),_c('v-divider',{staticClass:"mt-2 mb-1"})]:[_c('v-text-field',{attrs:{"id":"driver-initital-signature","disabled":"","hide-details":""}})],_c('label',{staticClass:"font-weight-bold",attrs:{"for":"driver-initital-signature"}},[_vm._v("Driver Signature")]),_c('div',{staticClass:"mt-4"},[_c('v-btn',{staticClass:"text-capitalize font-weight-bold px-6",attrs:{"dark":!_vm.driverSubmitData,"disabled":_vm.driverSubmitData,"color":"blue darken-2","depressed":"","height":"45"},on:{"click":function($event){return _vm.signAndSignature('driver')}}},[(_vm.driverSubmitData)?[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","size":"22","color":"blue"}}),_vm._v(" Please wait... ")]:_vm._e(),[_vm._v(" Driver Confirmed Signature ")]],2)],1)],2)],1)],1),_c('customer-sign-and-confirmed',{ref:"signAndConfirmed"},[_vm._v(" Please provide your signature and confirmed here! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }