<template>
	<v-row class="my-6">
		<v-col cols="12">
			<!-- <v-divider class="pb-4"></v-divider> -->
			<!-- {{getLead}} -->
			<v-row>
				<v-col cols="12" sm="12" md="5" lg="4">
					<h3 class="font-weight-medium pb-3 text-uppercase">
						<span class="font-weight-bold"> Enter Payment </span>
					</h3>
					<form @submit.prevent="submit">
						<v-card ref="form" tile outlined class="pt-3">
							<v-card-text>
								<v-text-field ref="name" name="name" label="Full Name" placeholder="John Doe" v-model="payment_form.name" required outlined></v-text-field>
								<v-text-field
									ref="email"
									name="email"
									label="Email Address"
									placeholder="example@example.com"
									v-model="payment_form.email"
									required
									outlined
								></v-text-field>
								<v-text-field
									ref="address"
									name="address"
									label="Address Line"
									placeholder="Snowy Rock Pl"
									counter="25"
									required
									v-model="payment_form.address"
									outlined
								></v-text-field>
								<v-text-field ref="city" name="city" label="City" placeholder="El Paso" required v-model="payment_form.city" outlined></v-text-field>
								<v-text-field ref="state" name="state" label="State/Province/Region" required placeholder="TX" v-model="payment_form.state" outlined></v-text-field>
								<v-text-field ref="zip" name="zip" label="ZIP / Postal Code" required placeholder="79938" v-model="payment_form.zip" outlined></v-text-field>

								<div id="stripe-element-mount-point" class="nes-input"></div>

								<div class="mt-4 text-right grey--text">
									{{ amountLabel }}
									<h2 class="font-weight-medium green--text" v-if="loading">
										<div v-if="amount > 0">
											Loading...
										</div>
										<div v-else>
											FULLY PAID
										</div>
									</h2>
									<h2 class="font-weight-medium green--text" v-else>
										{{ amount | formatDollarNumber }}
									</h2>
								</div>
							</v-card-text>
							<v-divider class="mt-1"></v-divider>
							<v-card-actions class="pa-4 grey lighten-5">
								<div class="text-left">
									<img style="width: 90%" :src="require('@/assets/secure-stripe-payment-logo-amex-master-visa.png')" />
								</div>
								<v-spacer></v-spacer>
								<v-btn type="submit" color="primary" class="font-weight-bold" depressed :disabled="loading">
									{{ payLabel }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</form>
				</v-col>

				<v-col cols="12" sm="12" md="7" lg="8">
					<h3 class="font-weight-medium pb-3 text-uppercase">
						<span class="font-weight-bold"> Payment History </span>
					</h3>
					<v-data-table
						:headers="headers"
						:items="paymentHistory"
						:footer-props="footerOptions"
						:options.sync="pagination"
						:items-per-page="pagination.itemsPerPage"
						:server-items-length="pagination.totalItems"
						:loading="loadingPaymentHistory"
						loading-text="Loading... Please wait"
					>
						<template v-slot:body="{ items }">
							<tbody v-if="items.length < 1">
								<tr>
									<td colspan="6">
										<h4 class="text-center blue-grey--text">No payment made yet.</h4>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr v-for="(item, i) in items" :key="i" class="every-template-name">
									<td>
										<div class="blue-grey--text text--darken-1 caption pl-1">
											<v-chip class="mx-1 font-weight-bold text-uppercase caption" color="success" label>
												<!-- {{ item.activity }} -->
												paid
											</v-chip>
										</div>
									</td>
									<td>
										<div class="blue-grey--text">
											{{ getDateOnly(item.date_paid) }}
										</div>
									</td>
									<td>
										<div class="grey--text text--darken-1 font-weight-bold text-capitalize">
											{{ item.method }}
											<span class="blue-grey--text text--lighten-2"> (ref.{{ item.notes }}) </span>
										</div>
									</td>
									<td class="text-right">
										<div class="grey--text text--darken-1 pr-4 text-right font-weight-bold"></div>
										{{ item.amount_paid | formatDollarNumber }}
									</td>
								</tr>
								<tr>
									<td colspan="4" class="text-right pr-4 font-weight-bold grey lighten-5">
										<span class="blue-grey--text"> Total Paid: </span>
										<span class="indigo--text text--lighten-2 pl-2"> {{ totalPaid | formatDollarNumber }}</span>
									</td>
								</tr>
								<tr>
									<td colspan="4" class="text-right pr-4 font-weight-bold grey lighten-5">
										<span class="blue-grey--text"> Grand Total: </span>
										<span class="indigo--text text--lighten-2 pl-2"> {{ grandTotal | formatDollarNumber }}</span>
									</td>
								</tr>
								<tr>
									<td colspan="4" class="text-right pr-4 font-weight-bold grey lighten-5">
										<span class="blue-grey--text">Remaining Balance:</span>
										<span class="primary--text text--darken-1 pl-2"> {{ remainingBalance | formatDollarNumber }}</span>
									</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<style>
#stripe-element-mount-point {
	border: #9e9e9e solid 1px;
	padding: 17px;
	border-radius: 4px;
}
</style>
<script>
import { loadStripe } from "@stripe/stripe-js";
import { onMounted, ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import store from "Store";
import router from "Router";

export default {
	name: "BillingTabFour",
	data() {
		return {
			elements: null,
			stripe: null,
			amount: 0.0,
			loading: true,
			lead_id: null,
			payLabel: "PAY NOW",
			amountLabel: "",
			payment_form: {
				name: "",
				email: "",
				address: "",
				city: "",
				state: "",
				zip: ""
			},
			headers: [
				{ text: "Activity", width: 90, sortable: false, class: "font-weight-bold warning--text text-center text-uppercase" },
				{ text: "Date", width: 120, sortable: false, class: "font-weight-bold warning--text text-uppercase" },
				{ text: "Method", width: 180, sortable: false, class: "font-weight-bold warning--text  text-uppercase" },
				{ text: "Total Amount", width: 30, sortable: false, class: "font-weight-bold warning--text text-center text-uppercase" }
			],
			pagination: {
				page: 1,
				itemsPerPage: 50,
				totalItems: 50
			},
			loadingPaymentHistory: false,
			footerOptions: {
				itemsPerPageOptions: [10]
			},
			paymentHistory: [],
			grandTotal: 0
		};
	},
	async mounted() {
		console.log("Mounted!");

		//this.loading = false;
		this.load_data();
		
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"]),
		totalPaid() {
			const totalPayment = this.paymentHistory.reduce((a, b) => +a + +b.amount_paid, 0);
			return totalPayment;
		},
		remainingBalance() {
			const bal = this.grandTotal - this.totalPaid;
			return bal;
		}
	},
	methods: {
		...mapActions("Notification", ["successNotice", "errorNotice"]),
		async load_data(){
			const ELEMENT_TYPE = "card";
			const pk_test = "pk_live_IALVhJolDntpCVxDH5GuXGJ8";
			this.stripe = await loadStripe(pk_test);
			this.elements = this.stripe.elements();
			const element = this.elements.create(ELEMENT_TYPE);
			element.mount("#stripe-element-mount-point");

			this.lead_id = this.$route.params.leadId;
			let data = {
				lead_id: this.lead_id
			};

			try {
				let stripeLead = await store.dispatch("Customer/get_stripe_lead", data);
				console.log("stripe Lead", stripeLead);
				this.amount = stripeLead.data.amount;

				if (stripeLead.data.paymentStatus == 0) {
					this.amountLabel = "AMOUNT TO DEPOSIT";
				} else {
					this.amountLabel = "REMAINING BALANCE";
				}

				this.paymentHistory = stripeLead.data.payments;
				this.grandTotal = stripeLead.data.totalBilling;

				//this.amount = this.grandTotal - this.totalPaid

				console.log('amount to pay', this.amount)
				if(this.amount <= 0){
					this.loading = true;
				}else{
					this.loading = false;
				}
			} catch (e) {
				this.loading = true;
			}
		},
		async payment_notification(message) {
			let emailpost = [];
			emailpost = {
				to: "jamesmailer23@gmail.com",
				from: "no-reply@competitiverelocation.com",
				fromname: "CRS Dev",
				subject: "Payment Intent Response - Created via FE",
				text: message,
				html: message
			};
			let stripeResponse = await store.dispatch("LeadsRelated/simplemail", emailpost);
			console.log("stripeResponse", stripeResponse);
		},
		async submit() {
			this.payLabel = "Processing...";

			if (this.loading) return;
			this.loading = true;

			const { name, email, address, city, state, zip } = Object.fromEntries(new FormData(event.target));
			console.log("here", name, email, address, city, state, zip);

			const billingDetails = {
				name,
				email,
				address: {
					city,
					line1: address,
					state,
					postal_code: zip
				}
			};

			const cardElement = this.elements.getElement("card");

			const amount_deposit = 100;
			const amount_deposit_cent = 100 * 10;
			let post = [];
			post = {
				lead_id: this.lead_id
			};

			let stripeResponse = await store.dispatch("Customer/create_payment_intent", post);
			console.log("stripeResponse", stripeResponse);
			console.log("payment_intent id", stripeResponse.data.payment_intent.id);

			if (stripeResponse.status == 200) {
				const secret = stripeResponse.data.secret;
				console.log("intent secret", secret);
				const paymentMethodReq = await this.stripe.createPaymentMethod({
					type: "card",
					card: cardElement,
					billing_details: billingDetails
				});

				console.log("paymentMethodReq", paymentMethodReq);
				if(paymentMethodReq.error  !== undefined){
					let errMethodCode = paymentMethodReq.error.code
					await this.errorNotice({
							title: errMethodCode.replace("_", " ").toUpperCase(),
							description: paymentMethodReq.error.message
						});

					// Reset Clear the Element:
					cardElement.clear();
					//this.clear_payment_form();
					this.loading = false;
				}else{
					try {

						const cardConfirm = await this.stripe.confirmCardPayment(secret, {
							payment_method: paymentMethodReq.paymentMethod.id
						});
						// console.log("cardConfirm.paymentIntent", cardConfirm.paymentIntent);
						// console.log("cardConfirm.error", cardConfirm.error);
						
						if(cardConfirm.paymentIntent !== undefined && cardConfirm.error == undefined){

							const piJSONstring = JSON.stringify(cardConfirm);

							if(cardConfirm.paymentIntent.status == "succeeded"){

								let dataConfirm = {
									payment_intent_id: stripeResponse.data.payment_intent.id
								};
								let stripePaymentInt = await store.dispatch("Customer/confirm_payment", dataConfirm);
								this.paymentHistory = stripePaymentInt.data.payments

								// Refresh
								this.load_data();

								console.log("stripePaymentInt!", stripePaymentInt);
								console.log("payments!", stripePaymentInt.data.payments);

								await this.successNotice({
									title: "Payment Sent",
									description: "Payment sent and confirmed."
								});
								this.clear_payment_form();
							}else{
								await this.errorNotice({
									title: "Something went wrong!", description: "Email not sent, encountered problems along the way."
								});
							}
							
						}else{
							let errCode = cardConfirm.error.code
							await this.errorNotice({
									title: errCode.replace("_", " ").toUpperCase(),
									description: cardConfirm.error.message
								});

							// Reset Clear the Element:
							cardElement.clear();
							//this.clear_payment_form();
							this.loading = false;

						}

						// Clear the Element:
						cardElement.clear();
						
						this.loading = false;
					} catch (error) {
						console.log("error", error);
						this.loading = false;
					}
				}
				
				
			}

			this.payLabel = "PAY NOW";
		},
		async clear_payment_form() {
			this.payment_form.name = "";
			this.payment_form.email = "";
			this.payment_form.address = "";
			this.payment_form.city = "";
			this.payment_form.state = "";
			this.payment_form.zip = "";
		}
	}
};
</script>

<style scoped>
>>> .v-data-table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table thead tr th {
	background: rgb(250 250 250);
}
</style>