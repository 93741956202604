<template>
	<v-row class="my-6">
		<v-col cols="12">
			<h3 class="font-weight-medium pb-1 text-uppercase">
				<span class="font-weight-bold"> Services </span>
				/ Valuation Coverage
			</h3>
			<v-divider></v-divider>
		</v-col>
		<v-col class="mt-8" cols="12">
			<h4 class="text-uppercase mb-1">CUSTOMERS DECLARED VALUE AND LIMIT OF COMPANYS LIABILITY</h4>
			<div>
				Since rates are based on the declared value of the property, and the customer (shipper) is required to declare in writing the released value of the property, the
				agreed or declared value of the property is hereby specifically stated to be not exceeding $0.60 cents per pound per article with a limit of $2,000.00 while being
				handled by the carrier.
			</div>
		</v-col>
		<v-col cols="12">
			<v-row class="align-end">
				<v-col cols="4 " class="text-center">
					<!-- <v-text-field class="mb-1" hide-details=""></v-text-field>
					<label for="">
						<span class="font-weight-bold"> Customer Signature </span>
					</label> -->
					<template
						v-if="
							getLead.hasOwnProperty('customerAction') &&
							getLead.customerAction.hasOwnProperty('valuationCoverageCustomerrInititalSignature') &&
							getLead.customerAction.valuationCoverageCustomerrInititalSignature.length > 0
						"
					>
						<v-img :src="getLead.customerAction.valuationCoverageCustomerrInititalSignature" max-height="125" contain class=""></v-img>
						<v-divider class="mt-2 mb-1"></v-divider>
					</template>
					<template v-else>
						<v-text-field id="customer-initital-signature" disabled hide-details=""></v-text-field>
					</template>
					<label for="customer-initital-signature" class="font-weight-bold">Customer Signature</label>

					<div class="mt-4">
						<v-btn
							:dark="!customerSubmitData"
							:disabled="customerSubmitData"
							color="blue darken-2"
							@click="signAndSignature('customer')"
							class="text-capitalize font-weight-bold"
							height="45"
							depressed
						>
							<template v-if="customerSubmitData">
								<v-progress-circular indeterminate size="22" class="mr-2" color="blue"></v-progress-circular>
								Please wait...
							</template>
							<template v-else> Customer Signature to Confirmed </template>
						</v-btn>
					</div>
				</v-col>
				<v-col cols="4" offset="1" class="text-center">
					<!-- <v-text-field class="mb-1" hide-details=""></v-text-field>
					<label for="">
						<span class="font-weight-bold"> Driver Signature </span>
					</label> -->
					<template
						v-if="
							getLead.hasOwnProperty('customerAction') &&
							getLead.customerAction.hasOwnProperty('valuationCoverageDriverInititalSignature') &&
							getLead.customerAction.valuationCoverageDriverInititalSignature.length > 0
						"
					>
						<v-img :src="getLead.customerAction.valuationCoverageDriverInititalSignature" max-height="125" contain class=""></v-img>
						<v-divider class="mt-2 mb-1"></v-divider>
					</template>
					<template v-else>
						<v-text-field id="driver-initital-signature" disabled hide-details=""></v-text-field>
					</template>
					<label for="driver-initital-signature" class="font-weight-bold">Driver Signature</label>

					<div class="mt-4">
						<v-btn
							:dark="!driverSubmitData"
							:disabled="driverSubmitData"
							color="blue darken-2"
							@click="signAndSignature('driver')"
							class="text-capitalize font-weight-bold px-6"
							depressed
							height="45"
						>
							<template v-if="driverSubmitData">
								<v-progress-circular indeterminate size="22" class="mr-2" color="blue"></v-progress-circular>
								Please wait...
							</template>
							<template> Driver Confirmed Signature </template>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-col>
		<customer-sign-and-confirmed ref="signAndConfirmed"> Please provide your signature and confirmed here! </customer-sign-and-confirmed>
	</v-row>
</template>

<script>
import CustomerSignAndConfirmed from "Components/Dialogs/CustomerSignAndConfirmed";
import { mapGetters, mapActions } from "vuex";
export default {
	name: "ValuationCaverage",
	components: {
		CustomerSignAndConfirmed
	},
	data() {
		return {
			driverSubmitData: false,
			customerSubmitData: false
		};
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"])
	},
	methods: {
		...mapActions("CustomerUnique", ["updateLead"]),
		...mapActions("Notification", ["successNotice"]),
		signAndSignature(trigger) {
			this.$refs.signAndConfirmed.open().then(async res => {
				if (!res.signed) {
					return;
				}
				let customerAction = this.getLead.customerAction;

				if (trigger === "customer") {
					customerAction["valuationCoverageCustomerrInititalSignatureDate"] = Date.now();
					customerAction["valuationCoverageCustomerrInititalSignature"] = res.svg;

					this.customerSubmitData = true;
				}
				if (trigger === "driver") {
					customerAction["valuationCoverageDriverInititalSignatureDate"] = Date.now();
					customerAction["valuationCoverageDriverInititalSignature"] = res.svg;
					this.driverSubmitData = true;
				}

				let authKey = this.$route.params.authKey;
				let crsKey = this.$route.params.crsKey;
				let id = this.$route.params.leadId;
				let customerId = this.$route.params.customerId;

				let params = {
					id,
					authKey,
					crsKey,
					leadId: id,
					customerId,
					customerAction
				};

				let update = await this.updateLead(params);

				// display success notification
				if (update.status == 200) {
					await this.successNotice({
						title: "Signature Comfirmed!!",
						description: "Your signature is saved."
					});
				}
				// remove btn loader
				this.customerSubmitData = false;
				this.driverSubmitData = false;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
</style>