import { mapGetters } from "vuex";

export default {
   computed: {
      ...mapGetters("CustomerUnique", ["getLead", "getCustomerData"]),
      estimates() {
         return [
            {
               description: `Initial Rate - $${this.getLocalInfoRate} hr / 2300 CF (${this.getApproxWeight} lbs) / ${this.getLocalInfoMenNeeded} ${this.getLocalInfoHoursNeeded}hours`,
               price: this.initialRate
            },
            {
               description: "Moving Supplies",
               price: this.getMovingSupplies
            },
            {
               description: "Additional Services",
               price: this.getAdditionalServices
            },
            {
               description: "Additional Charges",
               price: this.getAdditionalCharges
            },
            {
               description: "Truck Fee Charges",
               price: this.trucksFeeCharges
            },
            {
               description: "Transaction Fee",
               price: this.getTransactionFee
            },
            {
               description: "Discount",
               price: this.getLocalInfoDiscount,
               class: 'pink--text body-1',
               sign: '-'
            }
         ];
      },
      getDepositNeed() {
         let estimatedTotal = this.estimatedTotal || 0;
         let twentyFivePercent = 0.25;

         let pair1 = estimatedTotal * twentyFivePercent;
         let pair2 = +pair1 + +this.getTransactionFee;
         let result = pair2.toFixed(2);
         // Emit value outside
         this.$emit("initialPayment", result);

         return result;
      },
      getLocalInfoRate() {
         return this.getLead != null && this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("rate") ? this.getLead.localInfo.rate : 0;
      },
      getApproxWeight() {
         return this.getLead != null && this.getLead.hasOwnProperty("contactInfo") && this.getLead.contactInfo.hasOwnProperty("approxWeight")
            ? this.getLead.contactInfo.approxWeight
            : 0;
      },
      getLocalInfoMenNeeded() {
         return this.getLead != null && this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("menNeeded") ? this.getLead.localInfo.menNeeded : 0;
      },
      getLocalInfoHoursNeeded() {
         return this.getLead != null && this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("hoursNeeded")
            ? this.getLead.localInfo.hoursNeeded
            : 0;
      },
      getAdditionalServices() {
         return this.getLead != null && this.getLead.hasOwnProperty("additionalServices") ? this.getLead.additionalServices : 0;
      },
      getAdditionalCharges() {
         return (this.getLead != null) & this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("additionalCharges")
            ? this.getLead.localInfo.additionalCharges
            : 0;
      },
      getLocalInfoDiscount() {
         return this.getLead != null && this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("discount") && this.getLead.localInfo.discount > 0
            ? this.getLead.localInfo.discount
            : 0;
      },
      getSumEstimates() {
         let estimate = this.estimates.reduce((prevVal, currentVal) => {
            return +prevVal + +currentVal.price;
         }, 0);
         let disc =
            this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("discount") && this.getLead.localInfo.discount > 0
               ? this.getLead.localInfo.discount
               : 0;

         let subTotal = +estimate - +disc;
         let total = +subTotal - +this.getDepositNeed;

         let mainTotal = +total - +this.getLocalInfoDiscount
         // return total.toFixed(2);
         return mainTotal.toFixed(2);
      },
      initialRate() {
         let rate = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("rate") ? this.getLead.localInfo.rate : 0;
         let hoursNeeded = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("hoursNeeded") ? this.getLead.localInfo.hoursNeeded : 0;
         // return +this.getLead.localInfo.rate * +this.getLead.localInfo.hoursNeeded;
         return +rate * +hoursNeeded;
      },

      trucksFeeCharges() {
         let rate = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("rate") ? this.getLead.localInfo.rate : 0;
         let trucksFee = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("trucksFee") ? this.getLead.localInfo.trucksFee : 0;
         // return +this.getLead.localInfo.rate * +this.getLead.localInfo.trucksFee;
         return +rate * +trucksFee;
      },

      additionalTotalTruckCosts() {
         let additionalTruckRate =
            this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("additionalTruckRate") ? this.getLead.localInfo.additionalTruckRate : 0;
         // let additionalTruckRate = this.getLead.localInfo.additionalTruckRate || 0;
         // let hoursNeeded = this.getLead.localInfo.hoursNeeded || 0;
         let hoursNeeded = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("hoursNeeded") ? this.getLead.localInfo.hoursNeeded : 0;

         // let truckFee = this.getLead.localInfo.trucksFee || 0;
         let truckFee = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("trucksFee") ? this.getLead.localInfo.trucksFee : 0;
         let pair1 = truckFee + hoursNeeded;

         let pair2 = +pair1 * +additionalTruckRate;

         return pair2.toFixed(2);
      },

      getMovingSuppliesSubTotal() {
         let result = 0;
         this.getLead.movingSupplies.supplies.map(supply => (supply.total = supply.qty * supply.cost));

         result = this.getLead.movingSupplies.supplies.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.total;
         }, 0);
         return result;
      },

      getMovingSupplies() {
         let deliveryCharges =
            this.getLead.hasOwnProperty("movingSupplies") && this.getLead.movingSupplies.hasOwnProperty("deliveryCharges") ? this.getLead.movingSupplies.deliveryCharges : 0;
         // return +this.getMovingSuppliesSubTotal + +this.getLead.movingSupplies.deliveryCharges;
         return +this.getMovingSuppliesSubTotal + +deliveryCharges;
      },

      getPackingCost() {
         if (this.getLead.hasOwnProperty("packingCost")) {
            // let hoursNeeded = this.getLead.packingCost.hoursNeeded || 0;
            let hoursNeeded = this.getLead.packingCost.hasOwnProperty("hoursNeeded") ? this.getLead.packingCost.hoursNeeded : 0;
            // let hourlyRate = this.getLead.packingCost.hourlyRate || 0;
            let hourlyRate = this.getLead.packingCost.hasOwnProperty("hourlyRate") ? this.getLead.packingCost.hourlyRate : 0;
            hoursNeeded = hourlyRate != 0 && hoursNeeded == 0 ? 1 : hoursNeeded;
            hourlyRate = hoursNeeded != 0 && hourlyRate == 0 ? 1 : hourlyRate;

            let hourTimes = hoursNeeded * hourlyRate;
            // let costOfBoxes = this.getLead.packingCost.costBoxes || 0;
            let costOfBoxes = this.getLead.packingCost.hasOwnProperty("costBoxes") ? this.getLead.packingCost.costBoxes : 0;
            // let debrisRemoval = this.getLead.packingCost.debrisRemoval || 0;
            let debrisRemoval = this.getLead.packingCost.hasOwnProperty("debrisRemoval") ? this.getLead.packingCost.debrisRemoval : 0;

            return +hourTimes + +costOfBoxes + +debrisRemoval;
         }
         return 0;
      },

      estimatedTotal() {
         let total = 0;
         // let addlCharges = this.getLead.localInfo.additionalCharges || 0;
         let addlCharges = this.getLead.hasOwnProperty("localInfo") && this.getLead.localInfo.hasOwnProperty("additionalCharges") ? this.getLead.localInfo.additionalCharges : 0;

         let pair2 = +this.trucksFeeCharges + +this.additionalTotalTruckCosts + +addlCharges;
         let pair3 = +this.initialRate + +pair2;

         let discount = this.getLocalInfoDiscount;
         let pair4 = pair3 - discount;

         let addUp = +this.getMovingSupplies + +this.getPackingCost;

         let finalPair = pair4 + addUp;

         return finalPair.toFixed(2);
         // return +this.initialRate + +this.trucksFeeCharges + +this.additionalTotalTruckCosts + +sumAddlChargesAndDiscount + +this.getMovingSupplies + +this.getPackingCost;
      },
      getTransactionFee() {
         let est = this.estimatedTotal || 0;
         let transFee = est * 0.06;

         // round off two decimal
         // return Math.round((transFee + Number.EPSILON) * 100) / 100;
         return transFee.toFixed(2);
      }
   }
}