<template>
	<v-col cols="12">
		<v-simple-table fixed-header>
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left body-2 font-weight-bold bg-light-blue blue--text text--darken-4">Description</th>

						<th class="text-left body-2 font-weight-bold bg-light-blue blue--text text--darken-4">Price</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in estimates" :key="item.description">
						<td>
							<span class="font-weight-medium">{{ item.description }}</span>
						</td>
						<td>
							<span :class="item.hasOwnProperty('class') ? item.class : ''" class="font-weight-bold"
								>{{ item.hasOwnProperty("sign") ? item.sign : "" }}{{ item.price | formatDollarNumber }}</span
							>
						</td>
					</tr>
					<tr>
						<td>
							<v-row>
								<v-col cols="8" class="text-left text-uppercase caption font-weight-bold grey--text">
									<slot name="initialDepositNote"></slot>
								</v-col>
								<v-col cols="4" class="text-right font-weight-bold">Initial Deposit</v-col>
							</v-row>
						</td>
						<td class="text-right bg-light-green font-weight-bold">{{ getDepositNeed | formatDollarNumber }}</td>
					</tr>
					<tr>
						<td class="text-right font-weight-bold">Total</td>
						<td class="text-right bg-light-green font-weight-bold">{{ getSumEstimates | formatDollarNumber }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</v-col>
</template>

<script>
import billOfLading from "@/mixins/billOfLading";
export default {
	name: "EstimatesTable",
	mixins: [billOfLading]
};
</script>

<style lang="scss" scoped>
.bg-light-blue {
	background: #e1f5fe !important;
}
.bg-light-green {
	background: #69f0ae !important;
}
</style>