<template>
	<div>
		<v-row align="center" justify="center" class="mb-10">
			<!-- top header-->
			<v-col cols="12">
				<billingHeader></billingHeader>
			</v-col>
			<!-- Tabs area -->
			<v-col cols="12" class="warning pa-0">
				<v-container class="pa-0">
					<v-tabs v-model="tab" background-color="transparent" grow active-class="blue darken-2 font-weight-bold" height="60">
						<v-tabs-slider></v-tabs-slider>
						<v-tab href="#tab-1" class="py-2 text-uppercase white--text body-1" active-class="font-weight-bold blue darken-2">
							<!-- <span class="text-uppercase white--text body-1">  </span> -->
							Customer Info
						</v-tab>
						<v-tab href="#tab-2" class="text-uppercase white--text body-1" active-class="font-weight-bold blue darken-2"> Estimates </v-tab>

						<v-menu rounded="false" offset-y>
							<template v-slot:activator="{ attrs, on }">
								<!-- <v-btn color="indigo" class="white--text ma-5" v-bind="attrs" v-on="on"> Services </v-btn> -->
								<v-tab
									href="#tab-3"
									:disabled="tabLock"
									dark
									v-bind="attrs"
									v-on="on"
									class="text-uppercase white--text body-1"
									active-class="font-weight-bold blue darken-2"
								>
									Services
								</v-tab>
							</template>

							<v-list color="blue darken-2" dark flat>
								<v-list-item v-for="item in menuItems" :key="item.name" link class="services-menu-list" @click="servicesMenuSelect(item.id)">
									<v-list-item-title>
										<v-icon>mdi-chevron-right</v-icon>
										<span class="text-uppercase body-2 font-weight-medium ml-2">{{ item.name }} </span>
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>

						<v-tab href="#tab-4" :disabled="tabLock" class="text-uppercase white--text body-1" active-class="font-weight-bold blue darken-2"> Payments </v-tab>
					</v-tabs>
				</v-container>
			</v-col>
			<!-- Body section -->
			<v-col>
				<v-container>
					<v-tabs-items v-model="tab">
						<v-tab-item :value="'tab-1'">
							<tabOne></tabOne>
						</v-tab-item>
						<v-tab-item :value="'tab-2'">
							<tabTwo @tabUnlock="(status) => (tabLock = status)"></tabTwo>
						</v-tab-item>
						<v-tab-item :value="'tab-3'">
							<tabThree :servicesMenuSelected="servicesMenuSelected"></tabThree>
						</v-tab-item>
						<v-tab-item :value="'tab-4'">
							<tabFour></tabFour>
						</v-tab-item>
					</v-tabs-items>
				</v-container>
			</v-col>
		</v-row>

		<!-- Footer -->
		<v-footer padless class="mt-6" fixed>
			<v-col class="text-center" cols="12">
				<span class="body-2 font-weight-medium grey--text text-lighten-2">&copy; {{ new Date().getFullYear() }} All Rights Reserved. CRServices</span>
			</v-col>
		</v-footer>
	</div>
</template>


<script>
import billingHeader from "Components/clientsBilling/header";
// import tabOne from "Components/clientsBilling/billingTabs/tab1";
const tabOne = () => import(/* webpackChunkName: "Tab 1"*/ "Components/clientsBilling/billingTabs/tab1");
// import tabTwo from "Components/clientsBilling/billingTabs/tab2/index";
const tabTwo = () => import(/* webpackChunkName: "Tab 1"*/ "Components/clientsBilling/billingTabs/tab2/index");
import tabThree from "Components/clientsBilling/billingTabs/tab3/index";
import tabFour from "Components/clientsBilling/billingTabs/tab4";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
	name: "CustomerLeadsBilling",
	components: {
		billingHeader,
		tabOne,
		tabTwo,
		tabThree,
		tabFour
	},
	props: {
		customerId: {
			type: String,
			required: true
		},
		leadId: {
			type: String,
			required: true
		},
		authKey: {
			type: String,
			required: true
		},
		crsKey: {
			type: String,
			required: true
		}
		// :customer-id/:auth-key/:crs-key
		// customerId: "5ef56de21b6ec626da67a5eb"
		//leadId:'5efecbfef4d6a505bd30791c'
		// authKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlNDI4YzM5NjNhNzQwMTFlNThiZWJlMiIsImFjY291bnRfdHlwZSI6MiwiaWF0IjoxNjI3MTk2MzM2fQ.U3_9Ei56AA5nJHJLH4gJz-dBmaYCw5VNetByVK3bMUg"
		// crskey:"4ju4K87TrEH62zvsPp5F7j4Xd"

		// "http://localhost:8080/customer-lead/5ef56de21b6ec626da67a5eb/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlNDI4YzM5NjNhNzQwMTFlNThiZWJlMiIsImFjY291bnRfdHlwZSI6MiwiaWF0IjoxNjI3MTk2MzM2fQ.U3_9Ei56AA5nJHJLH4gJz-dBmaYCw5VNetByVK3bMUg/4ju4K87TrEH62zvsPp5F7j4Xd"
		// customer-lead/5efecbfdf4d6a505bd30791a/5efecbfef4d6a505bd30791c/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlNDI4YzM5NjNhNzQwMTFlNThiZWJlMiIsImFjY291bnRfdHlwZSI6MiwiaWF0IjoxNjI3MTk2MzM2fQ.U3_9Ei56AA5nJHJLH4gJz-dBmaYCw5VNetByVK3bMUg/4ju4K87TrEH62zvsPp5F7j4Xd
	},
	data() {
		return {
			tabLock: true,
			tab: null,
			text:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

			menuItems: [
				{
					name: "Bill of Lading",
					id: "BillOfLading"
				},
				{
					name: "Add Total Hours",
					id: "AddTotalHours"
				},
				{
					name: "Valuation Coverage",
					id: "ValuationCaverage"
				},
				{
					name: "High Value Inventory",
					id: "HighValueInventory"
				},
				{
					name: "Documents",
					id: "Documents"
				},
				{
					name: "Claims",
					id: "Claims"
				}
			],
			servicesMenuSelected: "BillOfLading",
			hasLead: false
		};
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"])
	},
	methods: {
		...mapActions("CustomerUnique", ["gettingAllCustomerLeads", "getAUser", "getAllInventoryItems"]),
		...mapMutations("CustomerUnique", ["ROUTE_HOME"]),
		servicesMenuSelect(menuSelected) {
			this.servicesMenuSelected = menuSelected;
		},
		async fetchThisAccount() {
			const credentials = {
				customerId: this.customerId,
				leadId: this.leadId,
				authKey: this.authKey,
				crsKey: this.crsKey
			};

			const client = await this.gettingAllCustomerLeads(credentials);
			if (client) {
				if (client.hasOwnProperty("success") && !client.success) {
					// this.$router.push({ name: 'page403', params: { errorMessage: "Unable to find the client records!" } })
					this.ROUTE_HOME("Unable to retrieved lead record!");
				}
			}
			this.hasLead = true;
		},

		async fetchAUser(userId) {
			const credentials = {
				crsKey: this.crsKey,
				authKey: this.authKey,
				userId
			};

			const user = await this.getAUser(credentials);
		}
	},

	mounted() {
		let tidio = document.createElement("script");
		tidio.setAttribute("src", "//code.tidio.co/cthfsonxi1kvq45i8rai6dviq2x7dskl.js");
		document.head.appendChild(tidio);
	},

	async created() {
		await this.fetchThisAccount();
		await this.getAllInventoryItems({ crsKey: this.crsKey, authKey: this.authKey });
		// check if user already confirmed and sign the estimates
		if (
			this.getLead != null &&
			this.getLead.hasOwnProperty("customerAction") &&
			this.getLead.customerAction.hasOwnProperty("confirmedEstimatesSignature") &&
			this.getLead.customerAction.confirmedEstimatesSignature.length > 0
		) {
			this.tabLock = false;
		}

		// CHECK IF CLIENT CONFIRMED
		if (!this.tabLock) {
			this.tab = "tab-4";
		}
	}
};
</script>

<style scoped>
.border-right {
	border-right: 1px solid #fff;
}
.services-menu-list {
	border-bottom: 1px solid #3089e2;
}
>>> .v-list .services-menu-list:last-child {
	border-bottom: none;
}
</style>