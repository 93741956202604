<template>
	<v-container class="pb-0">
		<v-row>
			<v-col>
				<appLogo></appLogo>
			</v-col>
		</v-row>
		<v-row class="mt-0" align="end">
			<v-col cols="6">
				<h3 class="text-uppercase">
					Moving
					<span class="font-weight-medium">
						#{{ getLead != null && getLead.hasOwnProperty("lead_id") ? getLead.lead_id.toString().padStart(4, "0") : "" }} &nbsp; | &nbsp;
						<span class="blue--text text--darken-2">{{ getLead != null && getLead.hasOwnProperty("leadType") ? getLead.leadType : "" }}</span></span
					>
				</h3>
			</v-col>
			<v-col cols="6">
				<v-row>
					<v-col cols="8" class="pb-1">
						<span class="font-weight-bold subtitle-1">
							Assigned Driver:
							<span class="font-weight-medium"> </span>
						</span>
					</v-col>
					<v-col cols="4" class="text-center">
						<a href="tel:+" class="blue pa-2 white--text text-decoration-none rounded text-uppercase body-2 font-weight-bold">
							<v-icon color="white" small> mdi-phone-outline </v-icon>
							Call
						</a>
					</v-col>
				</v-row>
				<v-row class="mt-1">
					<v-col cols="8">
						<span class="font-weight-bold subtitle-1">
							Assigned Agent:
							<span class="font-weight-medium">
								{{ getAssignedAgent != null && getAssignedAgent.hasOwnProperty("first_name") ? getAssignedAgent.first_name : "" }}
								{{ getAssignedAgent != null && getAssignedAgent.hasOwnProperty("last_name") ? getAssignedAgent.last_name : "" }}
							</span>
						</span>
					</v-col>
					<v-col cols="4" class="text-center">
						<a href="tel:+" class="blue pa-2 white--text text-decoration-none rounded text-uppercase body-2 font-weight-bold">
							<v-icon color="white" small> mdi-phone-outline </v-icon>
							Call
						</a>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import appLogo from "Components/app/appLogo";
import { mapGetters, mapActions } from "vuex";
export default {
	name: "BillingHeader",
	components: {
		appLogo
	},
	data() {
		return {};
	},
	watch: {
		getLead: {
			immediate: true,
			deep: true,
			async handler(lead) {
				if (lead != null && lead.hasOwnProperty("rel_assignedAgent")) {
					const { authKey, crsKey } = this.$route.params;
					const credentials = {
						authKey,
						crsKey,
						userId: this.getLead.rel_assignedAgent
					};
					await this.getAUser(credentials);
				}
			}
		}
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData", "getAssignedAgent"])
	},
	methods: {
		...mapActions("CustomerUnique", ["getAUser"])
	},
	async mounted() {
		// this.$nextTick(() => {
		// 	console.log(this.getLead, "boom");
		// });
	}
};
// http://localhost:8000/customer-lead/5efecbfdf4d6a505bd30791a/5efed123f4d6a505bd3079e9/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlNDI4YzM5NjNhNzQwMTFlNThiZWJlMiIsImFjY291bnRfdHlwZSI6MiwiaWF0IjoxNjI3MTk2MzM2fQ.U3_9Ei56AA5nJHJLH4gJz-dBmaYCw5VNetByVK3bMUg/4ju4K87TrEH62zvsPp5F7j4Xd
</script>

