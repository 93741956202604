<template>
	<v-row class="my-6">
		<v-col cols="12">
			<h3 class="font-weight-medium pb-1 text-uppercase">
				<span class="font-weight-bold"> Services </span>
				/ Documents
			</h3>
			<v-divider></v-divider>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Documents"
};
</script>

<style lang="scss" scoped>
</style>