<template>
	<v-row class="my-6">
		<v-col cols="12">
			<h3 class="font-weight-medium pb-1 text-uppercase">
				<span class="font-weight-bold"> Services </span>
				/ Add Total Hours
			</h3>
			<v-divider></v-divider>
		</v-col>
		<v-col cols="12" class="mt-6" v-if="getTimeDifference.length > 0">
			<h3 class="font-weight-bold text-uppercase">
				<span class="grey--text"> Total Hours: </span>
				{{ getTimeDifference }}
			</h3>
		</v-col>
		<v-col cols="11" class="mt-10">
			<v-row>
				<v-col cols="5">
					<label for="">
						<span class="font-weight-bold text-uppercase"> Start Time </span>
					</label>
					<v-text-field
						:value="timeWhenDriverHasSigned"
						placeholder="Start time after the driver has signed"
						class="my-1"
						hide-details=""
						disabled
						solo-inverted
						flat
					></v-text-field>
					<!-- <v-menu
						ref="menu"
						v-model="startTimeMenu"
						:close-on-content-click="false"
						:nudge-right="40"
						:return-value.sync="startTime"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								class="mt-1"
								v-model="startTime"
								placeholder="Start time after the driver has signed"
								clearable
								flat
								hide-details
								solo-inverted
								append-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"
								disabled
								:value="timeWhenDriverSigned"
							></v-text-field>
						</template>
						<v-time-picker v-if="startTimeMenu" v-model="startTime" full-width @click:minute="$refs.menu.save(startTime)"></v-time-picker>
					</v-menu> -->
				</v-col>
				<v-col cols="5">
					<label for="">
						<span class="font-weight-bold text-uppercase"> Finish Time </span>
					</label>
					<v-text-field
						:value="timeWhenReceiverHasSigned"
						placeholder="Set finish time after the receiver has done signing"
						class="my-1"
						hide-details=""
						disabled
						solo-inverted
						flat
					></v-text-field>
					<!-- <v-menu
						ref="menu"
						v-model="finishTimeMenu"
						:close-on-content-click="false"
						:nudge-right="40"
						:return-value.sync="finishTime"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								class="mt-1"
								v-model="finishTime"
								placeholder="Set finish time after the receiver has done signing"
								clearable
								flat
								hide-details
								solo-inverted
								append-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"
								disabled
							></v-text-field>
						</template>
						<v-time-picker v-if="finishTimeMenu" v-model="finishTime" full-width @click:minute="$refs.menu.save(finishTime)"></v-time-picker>
					</v-menu> -->
				</v-col>
			</v-row>
			<v-row class="align-end">
				<v-col cols="5">
					<div class="mt-10 text-center">
						<!-- <v-text-field class="mb-1" hide-details=""></v-text-field>
						<label for="">
							<span class="font-weight-bold"> Driver Signature </span>
						</label> -->
						<template v-if="checkDriverStartTime">
							<v-img :src="getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignature" max-height="125" contain class=""></v-img>
							<v-divider></v-divider>
						</template>
						<template v-else>
							<v-text-field id="driver-confirmed-signature" disabled hide-details=""></v-text-field>
						</template>
						<label for="driver-confirmed-signature" class="font-weight-bold">Driver Signature</label>
					</div>
					<div class="mt-8 text-center">
						<v-btn
							:dark="!driverSubmitData"
							:disabled="driverSubmitData"
							color="blue darken-2"
							@click="signAndSignature('driver')"
							class="text-capitalize font-weight-bold"
							height="45"
							depressed
						>
							<template v-if="driverSubmitData">
								<v-progress-circular indeterminate size="22" class="mr-2" color="blue"></v-progress-circular>
								Please wait...
							</template>
							<template v-else> Driver's Signature to Start Delivery Time </template>
						</v-btn>
					</div>
				</v-col>
				<v-col cols="5">
					<div class="mt-10 text-center">
						<!-- <v-text-field class="mb-1" hide-details=""></v-text-field>
						<label for="">
							<span class="font-weight-bold"> Receiver Signature </span>
						</label> -->
						<template v-if="checkReceiverFinishTime">
							<v-img :src="getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignature" max-height="125" contain class=""></v-img>
							<v-divider></v-divider>
						</template>
						<template v-else>
							<v-text-field id="receiver-confirmed-signature" disabled hide-details=""></v-text-field>
						</template>
						<label for="receiver-confirmed-signature" class="font-weight-bold">Receiver Signature</label>
					</div>
					<div class="mt-8 text-center">
						<v-btn
							:dark="!customerSubmitData"
							:disabled="customerSubmitData"
							color="blue darken-2"
							@click="signAndSignature('receiver')"
							class="text-capitalize font-weight-bold"
							height="45"
							depressed
						>
							<template v-if="customerSubmitData">
								<v-progress-circular indeterminate size="22" class="mr-2" color="blue"></v-progress-circular>
								Please wait...
							</template>
							<template v-else> Receiver's Signature to Finish Delivery Time </template>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-col>
		<customer-sign-and-confirmed ref="signAndConfirmed"> Please provide your signature and confirmed Time here! </customer-sign-and-confirmed>
	</v-row>
</template>

<script>
import CustomerSignAndConfirmed from "Components/Dialogs/CustomerSignAndConfirmed";
import { mapGetters, mapActions } from "vuex";
export default {
	name: "AddTotalHours",
	components: {
		CustomerSignAndConfirmed
	},
	data() {
		return {
			startTime: null,
			startTimeMenu: false,
			finishTime: null,
			finishTimeMenu: false,
			customerSubmitData: false,
			driverSubmitData: false
		};
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"]),
		checkDriverStartTime() {
			return (
				this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursDriverConfirmedSignature") &&
				this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignature.length > 0
			);
		},
		checkReceiverFinishTime() {
			return (
				this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursReceiverConfirmedSignature") &&
				this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignature.length > 0
			);
		},
		timeWhenDriverHasSigned() {
			return this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursDriverConfirmedSignatureDate") &&
				this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate.length > 0
				? this.getFullDate(this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate)
				: "";
		},
		timeWhenReceiverHasSigned() {
			return this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursReceiverConfirmedSignatureDate") &&
				this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate.length > 0
				? this.getFullDate(this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate)
				: "";
		},
		getStartTime() {
			// getTimeFirstWithDate(date){
			return this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursDriverConfirmedSignatureDate") &&
				this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate.length > 0
				? this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate
				: "";
		},
		getFinishTime() {
			// getTimeFirstWithDate(date){
			return this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("billOfLadingTotalHoursReceiverConfirmedSignatureDate") &&
				this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate.length > 0
				? this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate
				: "";
		},
		getTimeDifference() {
			let result = "";
			if (this.getStartTime.length > 0 && this.getFinishTime.length > 0) {
				let start = this.getLead.customerAction.billOfLadingTotalHoursDriverConfirmedSignatureDate;
				let finish = this.getLead.customerAction.billOfLadingTotalHoursReceiverConfirmedSignatureDate;

				let diffInMilliSeconds = Math.abs(new Date(start) - new Date(finish));

				const hour = Math.floor(diffInMilliSeconds / 3600000);
				const min = Math.floor(diffInMilliSeconds / 1000 / 60);

				result = `${min} min.`;
				if (hour > 0) {
					let hrExt = hour > 1 ? "hrs." : "hr.";
					result = `${hour} ${hrExt}`;
				}
				// diffInMilliSeconds -= hours * 3600;
				// console.log(result);
			}

			return result;
		}
	},
	methods: {
		...mapActions("CustomerUnique", ["updateLead"]),
		...mapActions("Notification", ["successNotice"]),

		signAndSignature(trigger) {
			this.$refs.signAndConfirmed.open().then(async res => {
				if (!res.signed) {
					return;
				}
				let customerAction = this.getLead.customerAction;

				if (trigger === "receiver") {
					customerAction["billOfLadingTotalHoursReceiverConfirmedSignatureDate"] = Date.now();
					customerAction["billOfLadingTotalHoursReceiverConfirmedSignature"] = res.svg;

					this.customerSubmitData = true;
				}
				if (trigger === "driver") {
					customerAction["billOfLadingTotalHoursDriverConfirmedSignatureDate"] = Date.now();
					customerAction["billOfLadingTotalHoursDriverConfirmedSignature"] = res.svg;

					this.driverSubmitData = true;
				}

				let authKey = this.$route.params.authKey;
				let crsKey = this.$route.params.crsKey;
				let id = this.$route.params.leadId;
				let customerId = this.$route.params.customerId;

				let params = {
					id,
					authKey,
					crsKey,
					leadId: id,
					customerId,
					customerAction
				};

				let update = await this.updateLead(params);

				// display success notification
				if (update.status == 200) {
					await this.successNotice({
						title: "Signature Comfirmed!!",
						description: "Your signature is saved."
					});
				}

				// remove btn loader
				this.customerSubmitData = false;
				this.driverSubmitData = false;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
</style>