<template>
	<div>
		<v-dialog v-model="dialog" max-width="700">
			<v-card>
				<div class="blue--text text--darken-2 px-4 font-weight-bold blue-grey lighten-5 py-6 pl-6">
					<slot> </slot>
				</div>
				<v-container class="">
					<v-row>
						<v-col cols="12" class="text-center px-8">
							<vueSignature ref="signature" :sigOption="option" :w="canvasWidth" :h="'250px'" :disabled="disabled"></vueSignature>

							<!-- <vueSignature ref="signature1" :sigOption="option" :w="'500px'" :h="'350px'"></vueSignature> -->
						</v-col>
					</v-row>
				</v-container>
				<v-card-actions class="grey lighten-5 py-4 px-6">
					<v-btn
						color="error lighten-1"
						text
						class="font-weight-bold"
						@click="
							dialog = false;
							$refs.signature.clear();
						"
					>
						Cancel
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="warning" @click="$refs.signature.clear()" class="font-weight-bold" depressed> Clear </v-btn>
					<v-btn color="primary" class="font-weight-bold" depressed dark @click="submit"> Submit </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import vueSignature from "vue-signature";
import { mapActions } from "vuex";
export default {
	name: "CustomerSignAndConfirmed",
	components: {
		vueSignature
	},
	data() {
		return {
			canvasWidth: "630px",
			dialog: false,
			reject: null,
			resolve: null,
			option: {
				penColor: "rgb(0, 0, 0)",
				backgroundColor: "#fbfbfb"
			},
			disabled: false
		};
	},

	methods: {
		...mapActions("Notification", ["errorNotice"]),
		submit() {
			if (this.$refs.signature.isEmpty()) {
				this.errorNotice({
					title: " Signature is needed!",
					description: "Please provide your signature, highly needed to proceed to the next step."
				});
				this.dialog = false;
				this.resolve({ signed: false });
				return;
			}
			let svg = this.$refs.signature.save("image/svg+xml");
			this.dialog = false;

			this.resolve({ signed: true, svg });
			this.$refs.signature.clear();
		},
		open() {
			this.dialog = true;
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		}
	}
};
</script>
<style scoped>
</style>